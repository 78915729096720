import SlickSlider from '../../../elements/SlickSlider'
import Container from '../../../elements/Container'
import Button from '../../../elements/Button'
import styles from './styles.module.scss'
import IconReview from '/public/icons/icon-review.svg'
import { useResponsive } from '@farfetch/react-context-responsive'
import moment from 'moment'
import Scroll from 'react-scroll'
import { useRouter } from 'next/router'
import IconMore from '/public/icons/icon-more.svg'

const Reviews = ({
  sectionRef,
  label,
  reviews,
  title,
  description,
  button
}) => {
  const responsive = useResponsive()
  const lessThan = responsive.lessThan
  const scroll = Scroll.animateScroll
  const router = useRouter()

  const executeScroll = () => {
    scroll.scrollTo(
      sectionRef.current?.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        150
    )
  }

  const isGuestExperience = router.asPath === '/guest-experience'

  return (
    <div>
      <div className={styles.intro}>
        <IconReview />
        {label && label}
      </div>

      {reviews && (
        <SlickSlider
          className={styles.review}
          infinite={false}
          slidesToShow={
            lessThan.xs ? 1 : lessThan.sm ? 1.25 : lessThan.lg ? 1.87 : 3
          }
        >
          {reviews.map((item, index) => (
            <div className={styles.review__card} key={index}>
              <div className={styles.review__date}>
                {moment(item.createdAt).format('MMM - YYYY')}
              </div>
              <p className={styles.review__title}>
                {item.public_review_field || item.public_review}
              </p>

              <h3 className={styles.review__author}>{item.fullName}</h3>
            </div>
          ))}
        </SlickSlider>
      )}

      <Container variant={['-xs']}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {description && (
          <p
            dangerouslySetInnerHTML={{
              __html: description
            }}
            className={styles.subtitle}
          ></p>
        )}

        {button &&
          (isGuestExperience ? (
            <Button
              className={styles.button}
              variant={['color-primary']}
              arrow={false}
              appendIcon={<IconMore />}
              onClick={() => sectionRef && executeScroll()}
            >
              {button}
            </Button>
          ) : (
            <Button variant={['secondary', 'blue']} href="/guest-experience">
              {button}
            </Button>
          ))}
      </Container>
    </div>
  )
}

export default Reviews
