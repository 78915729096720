import { Stream } from '@cloudflare/stream-react'

const CloudflareVideo = ({ src, reference, ...props }) => (
  <Stream
    streamRef={reference}
    src={src}
    width="100%"
    // eslint-disable-next-line no-undef
    poster={`https://customer-${process.env.NEXT_PUBLIC_CLOUDFLARE_CUSTOMER}.cloudflarestream.com/${src}/thumbnails/thumbnail.jpg`}
    height="auto"
    {...props}
  />
)

export default CloudflareVideo
