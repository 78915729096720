import Slider from 'react-slick'
import styles from './styles.module.scss'
import IconPrev from '/public/icons/icon-review-prev.svg'
import IconNext from '/public/icons/icon-review-next.svg'
import classNames from 'classnames'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useState, useRef } from 'react'
import isEmpty from 'lodash-es/isEmpty'
import { withModifiers } from '../../helpers/withModifiers'
import CloudflareVideo from '../CloudflareVideo'
import Image from 'next/image'
import { useModalContext } from '/providers/modalProvider'

const SlickSlider = ({
  pagination = false,
  arrows = true,
  className,
  slidesToShow,
  children,
  nextArrow,
  prevArrow,
  synced = false,
  videos,
  cloudflare,
  variant = [],
  ...props
}) => {
  const responsive = useResponsive()

  const isDesktop = responsive.lessThan.xl
  const isMobile = responsive.lessThan.xs
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  let [playing, setPlaying] = useState(videos && videos.map(() => false))
  const { show } = useModalContext()
  const cloudflareRefs = useRef(
    cloudflare && !isEmpty(cloudflare) ? cloudflare.map(() => useRef()) : []
  )

  const iconWidth = responsive.lessThan.xs ? 17 : 22

  // eslint-disable-next-line no-unused-vars
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <div {...props}>{children}</div>
  )

  return (
    <>
      <Slider
        className={classNames(
          withModifiers('slider', [...variant], styles),
          className,
          pagination && styles.withPagination
        )}
        asNavFor={synced && nav2}
        beforeChange={() => {
          if (playing < cloudflareRefs.current.length) {
            // Pause the video when changing slides
            const videoElement = cloudflareRefs.current[playing]?.current
            if (videoElement) {
              videoElement.pause()
            }
          }
          setPlaying(false)
        }}
        ref={(slider1) => synced && setNav1(slider1)}
        slidesToShow={slidesToShow || 3}
        dots={pagination}
        arrows={arrows}
        pagination={pagination && { clickable: true }}
        nextArrow={
          <SlickButtonFix>
            {nextArrow || <IconNext width={iconWidth} />}
          </SlickButtonFix>
        }
        prevArrow={
          <SlickButtonFix>
            {prevArrow || <IconPrev width={iconWidth} />}
          </SlickButtonFix>
        }
        {...props}
      >
        {show &&
          cloudflare &&
          !isEmpty(cloudflare) &&
          cloudflare.map((video, index) => {
            return (
              <CloudflareVideo
                playing={show && playing[index]}
                key={`video-${video}`}
                reference={cloudflareRefs.current[index]}
                controls
                src={video}
                onPlay={() => setPlaying(index)}
                onPause={() => setPlaying(false)}
                onEnded={() => setPlaying(false)}
              />
            )
          })}

        {children.length === 1 ? children : children.map((item) => item)}
      </Slider>

      {synced && (
        <Slider
          className={styles.slider__secondary}
          asNavFor={synced && nav1}
          ref={(slider2) => synced && setNav2(slider2)}
          arrows={false}
          slidesToShow={isMobile ? 3 : isDesktop ? 4 : 6}
          autoplay={false}
          focusOnSelect={true}
          accessibility={false}
          focusOnChange={false}
          {...props}
        >
          {cloudflare &&
            !isEmpty(cloudflare) &&
            cloudflare.map((_item) => (
              <Image
                key={_item}
                layout="fill"
                // eslint-disable-next-line no-undef
                src={`https://customer-${process.env.NEXT_PUBLIC_CLOUDFLARE_CUSTOMER}.cloudflarestream.com/${_item}/thumbnails/thumbnail.jpg`}
              />
            ))}

          {children.length === 1 ? children : children.map((item) => item)}
        </Slider>
      )}
    </>
  )
}

export default SlickSlider
